<template>
  <div class="card card-custom mt-4">
    <div class="card-body">
      <div
        class="d-flex justify-content-center align-items-center h-75"
        v-if="carregando"
      >
        <v-progress-circular indeterminate size="32" color="#462181" width="2"
          ><v-progress-circular
            indeterminate
            size="24"
            color="#ff7f00"
            width="2"
            rotate="180"
          ></v-progress-circular
        ></v-progress-circular>
      </div>

      <div
        class="d-flex align-items-end justify-content-end h-100 w-100 pr-5"
        v-else
      >
        <div class="d-flex align-items-center py-5 col-6 d-flex float-right">
          <span class="p-input-icon-left col-9 mr-5">
            <i class="pi pi-search ml-5" />
            <InputText
              placeholder="Buscar por nome da Agenda ou ID"
              type="text"
              class="w-100 ml-auto"
              v-model="filtros_agenda.nome"
              @input="filtrarAgenda"
            />
          </span>
          <b-button
            v-if="isAdmin || hasPermission('gerenciar-campanha')"
            variant="success"
            class="b-button-custom float-right col-3 mr-2"
            @click="clickBotaoCadastro"
          >
            Nova Agenda
          </b-button>
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-between align-items-between h-100"
      >
        <b-table
          id="tabelaAgenda"
          small
          :current-page="paginaAtual"
          :fields="cabecalho"
          :items="linhas_filtradas"
          :per-page="selectQuant.value"
          responsive="sm"
          hover
          sort-by="nome"
        >
          <template #cell(status)="data">
            <span
              v-if="data.value === 'A'"
              class="fa-solid fa-circle mr-2"
              style="color: #61bf03; font-size: 9px"
              v-b-tooltip.hover
              title="Agenda Ativa"
            ></span>
            <span
              v-else
              class="fa-solid fa-circle mr-2"
              style="color: #b22a20; font-size: 9px"
              v-b-tooltip.hover
              title="Agenda Inativa"
            ></span>
          </template>

          <template #cell(id)="data">
            <span>{{ data.value }} </span>
            <i
              class="fa-regular fa-copy"
              style="font-size: 12px; margin-left: 5px; color: #3699ff"
              :id="data.value + '-tooltip'"
              title="Copiar ID"
              @click="copiar(data.value)"
            ></i>

            <b-tooltip
              :ref="data.value"
              :target="data.value + '-tooltip'"
              triggers="click"
            >
              Copiado!
            </b-tooltip>
          </template>

          <template #cell(nome)="row">
            <span
              v-b-tooltip.hover
              title="Ver Agenda"
              @click="detalhes(row.item)"
              >{{ row.item.nome }}
            </span>
          </template>

          <template #cell(horarios)="row">
            <span>
              {{ row.item.horario_inicial }} - {{ row.item.horario_final }}
            </span>
          </template>

          <template #cell(intervalo)="row">
            <span>
              {{ row.item.intervalo_horario_inicial }} -
              {{ row.item.intervalo_horario_final }}
            </span>
          </template>

          <template #cell(tipo_atendimento)="data">
            <span v-if="data.value == 'HM'"> Horário Marcado </span>
            <span v-else-if="data.value == 'OC'"> Ordem de Chegada </span>
            <span v-else>{{ row.tipo_atendimento }} </span>
          </template>

          <template #cell(mar_tipo_moderacao)="data">
            <span
              v-if="data.value == 'CM'"
              class="fa-solid fa-shield mr-2"
              style="color: #3699ff; font-size: 20px"
              v-b-tooltip.hover
              title="Com Moderação"
            >
            </span>
            <span
              v-else-if="data.value == 'SI'"
              class="fa-solid fa-shield-halved mr-2"
              style="color: #3699ff; font-size: 20px"
              v-b-tooltip.hover
              title="Sem Moderação para Sem Identificação"
            >
            </span>
            <img
              class="mr-2"
              v-else-if="data.value == 'SM'"
              width="20"
              height="20"
              src="/icon/regular-shield.svg"
              loading="lazy"
              v-b-tooltip.hover
              title="Sem Moderação"
            />
          </template>

          <template #cell(utiliza_matriz)="data">
            <span
              v-if="data.value"
              class="fa-solid fa-check mr-2"
              style="color: #61bf03; font-size: 20px"
              v-b-tooltip.hover
              title="Utiliza Regras de Matriz"
            >
            </span>
            <span
              v-else
              class="fa-regular fa-circle mr-2"
              v-b-tooltip.hover
              title="Não utiliza Regras de Matriz"
            ></span>
          </template>

          <template #cell(actions)="row">
            <b-button
              class="mr-2"
              variant="outline-secondary"
              href="#"
              @click="editarAgenda(row.item)"
              v-if="isAdmin || hasPermission('gerenciar-campanha')"
              v-b-tooltip.hover
              title="Editar Agenda"
            >
              <span class="fa-solid fa-pen"></span>
            </b-button>

            <b-dropdown
              no-caret
              variant="outline-secondary"
              toggle-class="btn-icon"
              dropleft
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v p-0"></i>
              </template>

              <b-dropdown-item href="#" @click="detalhes(row.item)"
                >Detalhes
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                @click="duplicarAgenda(row.item)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Duplicar
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                @click="excluir(row.item.id, row.index)"
                v-if="isAdmin || hasPermission('gerenciar-campanha')"
                >Remover
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="paginaAtual"
                :total-rows="linhas_filtradas.length"
                :per-page="porPagina"
                aria-controls="tabelaAgenda"
                align="fill"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>

      <b-modal
        id="modal-detalhes-agenda"
        body-class="p-0"
        size="xl"
        hide-footer
        hide-header
      >
        <modal-detalhes-agenda
          @botao-voltar="$bvModal.hide('modal-detalhes-agenda')"
          :agenda="itemSelecionado"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import DetalheAgenda from "./DetalheAgenda.vue";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    "modal-detalhes-agenda": DetalheAgenda,
    InputText
  },

  data() {
    return {
      selectQuant: {
        value: 7,
        options: [
          { value: 7, text: "7" },
          { value: 10, text: "10" },
          { value: 15, text: "15" },
          { value: 20, text: "20" },
          { value: 25, text: "25" },
          { value: 30, text: "30" }
        ]
      },
      carregando: true,
      cabecalho: [
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "nome",
          label: "Nome",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "horarios",
          label: "Horários",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "intervalo",
          label: "Intervalo",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "tipo_atendimento",
          label: "Tipo de atendimento",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "mar_tipo_moderacao",
          label: "Moderação",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "utiliza_matriz",
          label: "Matriz",
          sortable: true,
          class: "cursor-pointer align-middle text-center"
        },
        {
          key: "actions",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        }
      ],
      linhas: [],
      linhas_filtradas: [],
      paginaAtual: 1,
      itemSelecionado: {},
      filtros_agenda: {
        nome: null
      }
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    porPagina() {
      return this.selectQuant.value;
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agende" },
      {
        title: "Agenda",
        route: "/painel/agende/agenda"
      }
    ]);
    this.listarAgendas();
  },

  methods: {
    clickBotaoCadastro() {
      this.$router.push({
        path: "/painel/automacao/cadastro-agenda"
      });
    },
    listarAgendas() {
      ApiService.get("agenda").then((response) => {
        this.linhas = response.data.data;
        this.linhas_filtradas = JSON.parse(JSON.stringify(this.linhas));

        this.carregando = false;
      });
    },
    copiar(texto) {
      this.$refs[texto].$emit("open");
      navigator.clipboard.writeText(texto);
      setTimeout(() => {
        this.$refs[texto].$emit("close");
      }, 1000);
    },
    filtrarAgenda() {
      let linhas_filtradas = null;
      const pesquisar = this.filtros_agenda.nome
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      linhas_filtradas = this.linhas.filter(
        (f) =>
          f.nome
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(pesquisar) || `${f.id}`.includes(pesquisar)
      );

      this.linhas_filtradas = linhas_filtradas;
    },
    editarAgenda(agenda) {
      this.$router.push({
        name: "automacao.agenda.cadastro",
        params: {
          agendaObj: agenda
        }
      });
    },
    duplicarAgenda(agenda) {
      Swal.fire({
        title: "Deseja realmente duplicar esta agenda?",
        showCancelButton: true,
        confirmButtonText: "Duplicar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-info"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get(`agenda/duplicar/${agenda.id}`).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Agenda duplicada com sucesso!",
              icon: "success",
              heightAuto: false
            });

            this.listarAgendas();
          });
        }
      });
    },

    detalhes(item) {
      this.itemSelecionado = item;

      this.$bvModal.show("modal-detalhes-agenda");
    },

    excluir(id, index) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir esta agenda?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.delete(`agenda/${id}`).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Agenda excluída com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.linhas.splice(index, 1);
            self.linhas_filtradas.splice(index, 1);
            self.filtrarAgenda();
          });
        }
      });
    }
  }
};
</script>

<style>
.b-dropdown-item-danger > .dropdown-item {
  color: #dc3545 !important;
  background-color: #fff !important;
  border-color: #dc3545 !important;
}

.b-dropdown-item-danger :hover {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>
